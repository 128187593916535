<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-3 text-nowrap" style="min-width: 150px"
                >{{ $t('landingPage.settingFreeBlock.title') }} <span class="text-danger">*</span></label
            >
            <div class="col-sm-9">
                <InputText
                    :model.sync="dataFormTitle.title"
                    :config.sync="configFormTitleFreeBlock.configFieldTitle"
                    @blur="handleValidateField(configFormTitleFreeBlock.configFieldTitle)"
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFreeBlock.category') }} </label>
            <div class="col-sm-9">
                <InputText
                    :model.sync="dataFormTitle.category"
                    :config.sync="configFormTitleFreeBlock.configFieldCategory"
                    @blur="handleValidateField(configFormTitleFreeBlock.configFieldCategory)"
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('landingPage.settingFreeBlock.detail') }} </label>
            <div class="col-sm-9">
                <Editor
                    v-if="isVisibleEditor"
                    :id="`ActionTitleFree`"
                    :model.sync="dataFormTitle.html_content"
                    :modelEvents="`change keydown blur focus paste`"
                    :configInit="configEditor"
                    @init="onEditorInit"
                />
            </div>
        </div>
        <div class="d-flex justify-content-center" style="gap: 1rem">
            <button class="btn btn-light" @click="handleCancel">{{ $t('btn.cancel') }}</button>
            <button class="btn btn-success" @click="handleSave">{{ $t('btn.save') }}</button>
        </div>
    </div>
</template>

<script>
import { configFormTitleFreeBlock } from './constants';
import { InputText } from '@/components/Input';
import { validateField, validateAllField } from '@/utils/validate';

import Editor from '@/components/Editor/main.vue';

export default {
    name: 'ActionFreeBlock',
    components: {
        InputText,
        Editor
    },
    props: {
        dataTitle: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            configFormTitleFreeBlock: configFormTitleFreeBlock,
            dataFormTitle: {
                title: '',
                category: '',
                html_content: ''
            },
            configEditor: {
                height: 400
            },
            objEditor: null,
            isVisibleEditor: false
        };
    },
    methods: {
        handleValidateField(configField) {
            validateField(configField);
        },
        handleCancel() {
            let { configFieldTitle } = this.configFormTitleFreeBlock;
            configFieldTitle.error = false;
            this.$emit('cancel');
        },

        handleSave() {
            let checkValidate = validateAllField(configFormTitleFreeBlock);
            if (!checkValidate) return;
            if (this.dataFormTitle?.id || (this.dataFormTitle?.make_id && this.dataFormTitle?.typeAction === 'Edit')) {
                this.$emit('updateData', this.dataFormTitle);
            } else {
                this.dataFormTitle.typeAction = 'Edit';
                this.$emit('addData', this.dataFormTitle);
            }
        },

        onEditorInit(editor) {
            this.objEditor = editor;
        }
    },
    watch: {
        dataTitle: {
            handler: function (newTitle) {
                this.dataFormTitle = {
                    ...this.dataFormTitle,
                    ...newTitle
                };
            },
            immediate: true
        }
    },
    mounted() {
        setTimeout(() => {
            this.isVisibleEditor = true;
        }, 300);
    }
};
</script>

<style lang="scss" scoped></style>
