import i18n from '@/i18n';

const configFormTitleFreeBlock = {
    configFieldTitle: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingFreeBlock.titleBlock'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['required', 'max255']
    },
    configFieldCategory: {
        isNumber: false,
        isMaxLength: false,
        error: false,
        errorField: i18n.t('landingPage.settingFreeBlock.category'),
        errorCustomMess: '',
        valueSync: '',
        typesValidate: ['max255']
    }
};

export { configFormTitleFreeBlock };
